import {Route, button, Routes} from "react-router-dom";
import React from "react";

export default function Header() {
    function termChange() {
        alert("현재 준비중인 기능입니다. \n 해당 기능은 sales@ubcn.co.kr 로 문의 부탁드립니다.");
    }

    function termRegist() {
        /*if(confirm("자료실에서 양식을 다운로드 받으실 수 있습니다. \n 자료실로 이동할까요?")){
            document.location.href = "/views/customer/download.jsp";
        }*/
    }

    return (
        <div id="header" className="fixed">
            <div className="header_in">
                <h1><a href="/" to={"/"}></a></h1>
                <div className="topUtil">
                    <div className="molink3">
                        <a href="https://biz.ubcn.co.kr/term_change" onClick={() => termChange()} rel="noreferrer"
                           target="_blank">단말기 명의변경 신청서</a>
                    </div>
                    <div className="molink2">
                        <a href="https://biz.ubcn.co.kr/term_regist" onClick={() => termRegist()} rel="noreferrer"
                           target="_blank">단말기 신청서</a>
                    </div>
                    <div className="menuBtn">
                        <a className="btnMenu_m"></a>
                        <a href="/views/other/sitemap.jsp"></a>
                    </div>
                </div>
            </div>
            <div id="gnb">
                <div id="menu">
                    <ul className="menu">
                        <li><span className="gnb_1depth"><a href="/views/product/product.jsp">제품</a></span>
                            <div className="menu_depth">
                                <ul>
                                    <li><a href="/views/product/product.jsp">Total</a></li>
                                    <li><a href="/views/product/product.jsp">Vending</a></li>
                                    <li><a href="/views/product/product.jsp">Access Control</a></li>
                                    <li><a href="/views/product/product.jsp">IC Reader</a></li>
                                    <li><a href="/views/product/product.jsp">RF Reader</a></li>
                                    <li><a href="/views/product/product.jsp">MultiPAD</a></li>
                                    <li><a href="/views/product/product.jsp">AFC</a></li>
                                </ul>
                            </div>
                        </li>
                        <li><span className="gnb_1depth"><a href="/views/service/umms.jsp">서비스</a></span>
                            <div className="menu_depth">
                                <ul>
                                    <li><a href="/views/service/umms.jsp">무인기기 관리</a></li>
                                    <li><a href="/views/service/ecps.jsp">사원카드 결제</a></li>
                                    <li><a href="/views/service/usms.jsp">신용카드 출입</a></li>
                                </ul>
                            </div>
                        </li>
                        <li><span className="gnb_1depth"><a href="/views/customer/data.jsp">고객지원</a></span>
                            <div className="menu_depth">
                                <ul>
                                    <li><a href="/views/customer/data.jsp">공지사항</a></li>
                                    <li><a href="/views/customer/qna.jsp">문의하기</a></li>
                                    <li><a href="/views/customer/result.jsp">설치사례</a></li>
                                </ul>
                            </div>
                        </li>
                        <li><span className="gnb_1depth"><a href="/views/company/about.jsp">회사소개</a></span>
                            <div className="menu_depth">
                                <ul>
                                    <li><a href="/views/company/about.jsp">기업개요</a></li>
                                    <li><a href="/views/company/history.jsp">연혁</a></li>
                                    <li><a href="/views/company/partner.jsp">고객사</a></li>
                                    <li><a href="/views/company/location.jsp">찾아오시는 길</a></li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="bg"></div>
        </div>
    );
}