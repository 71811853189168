import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import Layout from "./pages/common/Layout";
import HomeContainer from "./pages/content/HomeContainer";

/*
TODO: react-router-dom v6.0↑ 으로 기존 Switch 에서 Routes 로 변경 - twkim
 * index.jsx 페이지는 전처리 기능으로 활용성 높이고자 App 에서 Handler
 * 
 */

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={
                  <div id="wrap" className="wrap">
                      <Layout>
                          <HomeContainer/>
                      </Layout>
                  </div>
              } />
          </Routes>
      </BrowserRouter>
  );
}
export default App;
