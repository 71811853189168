import React from "react";
import MainVisual01 from "../../resources/img/main_visual01.jpg";
import TopClose from "../../resources/img/top_close.png";
import Logo2 from "../../resources/img/logo2.png";

export default function HomeContainer() {
    return (
        <>
            {/* 모바일 Burger Menu */}
            <div id="menuArea">
                <div className="menuList">
                    <h1>
                        <a href="/" className="menu_logo">
                            <img src={Logo2} alt="로고"/>
                        </a>
                        <button type="button" className="btnMenu_mClose" title="close">
                            <img src={TopClose} alt="Close"/>
                        </button>
                    </h1>
                    <ul className="list">
                        <li className="icon01">
                            <a className="dropdown-toggle">제품<span className="icon"></span></a>
                            <ul className="sMenu">
                                <li><a href="/views/product/product.jsp">Total</a></li>
                                <li><a href="/views/product/product.jsp">Vending</a></li>
                                <li><a href="/views/product/product.jsp">Access Control</a></li>
                                <li><a href="/views/product/product.jsp">IC Reader</a></li>
                                <li><a href="/views/product/product.jsp">RF Reader</a></li>
                                <li><a href="/views/product/product.jsp">MultiPAD</a></li>
                                <li><a href="/views/product/product.jsp">AFC</a></li>
                            </ul>
                        </li>
                        <li className="icon01">
                            <a className="dropdown-toggle">서비스<span className="icon"></span></a>
                            <ul className="sMenu">
                                <li><a href="/service/umms.jsp">무인기기 관리</a></li>
                                <li><a href="/service/ecps.jsp">사원카드 결제</a></li>
                                <li><a href="/service/usms.jsp">신용카드 출입</a></li>
                            </ul>
                        </li>
                        <li className="icon01">
                            <a className="dropdown-toggle">고객지원<span className="icon"></span></a>
                            <ul className="sMenu">
                                <li><a href="/views/customer/data.jsp">공지사항</a></li>
                                <li><a href="/views/customer/qna.jsp">문의하기</a></li>
                                <li><a href="/views/result/result.jsp?ptype=list&amp;catcode=18100000">설치사례</a></li>
                            </ul>
                        </li>
                        <li className="icon01">
                            <a className="dropdown-toggle">회사소개<span className="icon"></span></a>
                            <ul className="sMenu">
                                <li><a href="/views/company/about.jsp">기업개요</a></li>
                                <li><a href="/views/company/history.jsp">연혁</a></li>
                                <li><a href="/views/company/partner.jsp">고객사</a></li>
                                <li><a href="/views/company/location.jsp">찾아오시는 길</a></li>
                            </ul>
                        </li>
                    </ul>
                    <div className="molink"><a href="https://biz.ubcn.co.kr/term_regist" rel="noreferrer"
                                               target="_blank">단말기 신청서</a>
                    </div>
                    <div className="molink"><a href="https://biz.ubcn.co.kr/term_change" rel="noreferrer"
                                               target="_blank">단말기 명의변경
                        신청서</a></div>
                </div>
            </div>
            <div id="visual" role="toolbar">
                <div className="video_box slider">
                    <div><img src={MainVisual01} alt={"메인"}/></div>
                </div>
                <div className="v_txt">
                    <h3>Ubiquitous<br/>Communication</h3>
                    <p>지불, 결제시스템의 변화와 더불어 진화를 거듭해온 유비씨엔</p>
                </div>
                <div className="scroll_down">
                    <a href="#contents" className="mouse_wheel _mPS2id-h"><i className="actions"></i></a>
                </div>
            </div>
        </>
    );
}